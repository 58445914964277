import * as Clipboard from "expo-clipboard"
import React from "react"

import { captureException } from "@sentry/core"

import i18n from "../translations/i18n"
import { useToasts } from "./useToasts"

/**
 * A hook that provides a function to copy a string to the clipboard.
 */
export function useClipboard() {
  const [hasCopied, setHasCopied] = React.useState(false)
  const [value, setValue] = React.useState<string>("")
  const toast = useToasts()
  // TODO: use expo-clipboard
  const onCopy = React.useCallback(
    (copiedValue: string, options?: { successMessage?: string }) => {
      Clipboard.setStringAsync(copiedValue)
        .then(() => setValue(copiedValue))
        .then(() =>
          toast.info(options?.successMessage ?? i18n.t("copiedToClipboard")),
        )
        .then(() => setHasCopied(true))
        .catch((error) => {
          captureException(error)
        })
    },
    [toast],
  )
  return { hasCopied, onCopy, value }
}
