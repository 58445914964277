import React from "react"
import { useNavigate } from "react-router-dom"

import {
  getIsUserAuthenticatedFromState,
  i18n,
  useRootSelector,
} from "@fhq/app"
import { ScrollView } from "@fhq/app/components"
import { CreateSupportTicketForm } from "@fhq/app/CreateSupportTicketForm"

import { Appbar } from "./base/Appbar"
import { Page } from "./base/Page"

/**
 * TODO: This is a copy of CreateSupportIssueScreen. It should be refactored to use the same component.
 * TODO: Layout is a little weird
 */
export default function CreateSupportTicketPage(): React.JSX.Element {
  const navigate = useNavigate()
  const isAuthenticated = useRootSelector(getIsUserAuthenticatedFromState)
  return (
    <React.Fragment>
      <Appbar />
      <ScrollView variant="pageScroll">
        <Page
          IconComponent="CustomerService"
          id="create-support-ticket"
          maxW="$2xl"
          mt="$4"
          px="$4"
          titleText={i18n.t("contactUs")}
        >
          <CreateSupportTicketForm
            showTitle={false}
            onClose={() => {
              if (history.length > 0) {
                return navigate(-1)
              } else if (isAuthenticated) {
                return navigate("/account")
              }
              return navigate("/sign-in")
            }}
          />
        </Page>
      </ScrollView>
    </React.Fragment>
  )
}
