import React from "react"
import { StyleSheet } from "react-native"

import { Auth, FarmHqLogo, isTruthyString } from "@fhq/app"
import { useScreenSizeBreakpoint } from "@fhq/app/app-state.reducer"
import { AppText, Box, ScrollView } from "@fhq/app/components"
import { Theme } from "@fhq/app/components/theme"

import { Environment } from "./base"
import { PageContainer } from "./PageContainer"

import type { AcceptsChildren, TestIdProps } from "@fhq/app/components"

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
  contentContainerLargeScreen: {
    paddingTop: Theme.spacing.$8,
  },
  logo: {
    height: Theme.sizes.$32,
    width: "100%",
  },
})

const formStyle = { flex: 1 }
/**
 *
 */
export function AuthLayout({
  id,
  ...props
}: AcceptsChildren & TestIdProps & { title?: string }): React.JSX.Element {
  const screenSize = useScreenSizeBreakpoint()
  const isLargeScreen =
    screenSize === "md" || screenSize === "lg" || screenSize === "xl"
  return (
    <ScrollView
      contentContainerStyle={[
        styles.contentContainer,
        isLargeScreen && styles.contentContainerLargeScreen,
      ]}
    >
      <PageContainer preserveId flex={1} id={id} maxW="$lg" mx="auto" w="$full">
        <Box flex={1} mt="$4" mx="auto" px="$4" w="$full">
          <Box mb="$4" position="relative">
            <FarmHqLogo
              name="farmhq-logo-inline.png"
              resizeMode="contain"
              style={styles.logo}
            />
            {Environment.targetDatabaseName === "PROD" ? null : (
              <Box
                bottom="$-2"
                left="$1/2"
                position="absolute"
                right="$1/2"
                w="$full"
              >
                <AppText colorScheme="secondary">
                  {Environment.targetDatabaseName}
                </AppText>
              </Box>
            )}
          </Box>
          {isTruthyString(props.title) ? (
            <Box my="$4">
              <Auth.PageTitle>{props.title}</Auth.PageTitle>
            </Box>
          ) : null}
          <form style={formStyle}>{props.children}</form>
        </Box>
      </PageContainer>
    </ScrollView>
  )
}
