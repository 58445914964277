import React from "react"
import { View } from "react-native"

import { isTruthyString, isValidNumber } from "../type-guards"
import { Box } from "./Box"
import { Heading } from "./Heading"
import { AppIcons, renderIconFromIconProp } from "./icons"
import { ListNumber } from "./ListNumber"
import { Pressable } from "./Pressable"
import { Row } from "./Row"
import { AppText, TextWithIcon } from "./Text"

import type { ViewStyle } from "react-native"
import type { PressableProps } from "./Pressable"

import type { BoxProps } from "./Box"
import type { HeadingProps } from "./Heading"
import type { IconProp } from "./icons"
import type { OptionalTestId } from "./test-id"
import type { AppTextProps } from "./Text"
import type { ElementOrComponent } from "./types"
// const styles = StyleSheet.create({
//   listItemLoader: {
//     bottom: 0,
//     left: 0,
//     opacity: 30,
//     position: "absolute",
//     right: 0,
//     top: 0,
//   },
// })

type ListItemTextProps = AppTextProps
export function ListItemTextPrimary({
  children,
  ...rest
}: ListItemTextProps): React.JSX.Element | null {
  return <AppText {...rest}>{children}</AppText>
}

export function ListItemTextSecondary({
  children,
  ...rest
}: ListItemTextProps): React.JSX.Element | null {
  return (
    <AppText colorScheme="secondary" {...rest}>
      {children}
    </AppText>
  )
}

export function ListItemContainer(props: BoxProps): React.JSX.Element {
  return <Box px="$4" py="$3" {...props} />
}

/**
 * ListItemLink
 */
export function ListItemLink({
  IconComponent,
  onPress,
  text,
  ...rest
}: Omit<PressableProps, "children"> & {
  IconComponent: IconProp
  text: React.JSX.Element | string
}) {
  return (
    <Pressable
      trackHover
      trackPress
      accessibilityRole="link"
      onPress={onPress}
      {...rest}
    >
      <ListItemContainer>
        <Row flexWrap="nowrap">
          <TextWithIcon IconComponent={IconComponent} text={text} />
          <Box ml="auto" mr="$2">
            <AppIcons.ListItemEnd />
          </Box>
        </Row>
      </ListItemContainer>
    </Pressable>
  )
}

export interface ListItemProps extends OptionalTestId {
  EndComponent?: ElementOrComponent
  IconComponent?: IconProp

  numberedValue?: number
  secondary?: React.ReactNode
  startElement?: React.ReactNode
  text?: React.ReactNode
}

/**
 *
 */
export function ListItem({
  EndComponent,
  IconComponent,
  // isLoading = false,
  numberedValue,
  secondary,
  text: textPrimary,
  ...rest
}: ListItemProps): React.JSX.Element {
  let position: ViewStyle["position"] | undefined
  let loaderElement: React.JSX.Element | undefined

  const iconElement = renderIconFromIconProp(IconComponent)

  const numberElement = isValidNumber(numberedValue) ? (
    <ListNumber value={numberedValue} />
  ) : null

  const startElement = isTruthyString(rest.startElement) ? (
    <ListItemTextSecondary>{rest.startElement}</ListItemTextSecondary>
  ) : React.isValidElement(rest.startElement) ? (
    rest.startElement
  ) : null

  return (
    <ListItemContainer position={position} {...rest}>
      <Row flexWrap="nowrap">
        {loaderElement}
        {iconElement || numberElement || startElement ? (
          <Box mr="$2">
            {iconElement}
            {numberElement}
            {startElement}
          </Box>
        ) : null}
        <View>
          {typeof textPrimary === "string" ? (
            <ListItemTextPrimary>{textPrimary}</ListItemTextPrimary>
          ) : React.isValidElement(textPrimary) ? (
            textPrimary
          ) : null}
          {typeof secondary === "string" ? (
            <ListItemTextSecondary>{secondary}</ListItemTextSecondary>
          ) : null}
        </View>
        <Box ml="auto">
          {typeof EndComponent === "function" ? (
            <EndComponent />
          ) : typeof EndComponent === "string" ? (
            <ListItemTextSecondary>{EndComponent}</ListItemTextSecondary>
          ) : React.isValidElement(EndComponent) ? (
            EndComponent
          ) : null}
        </Box>
      </Row>
    </ListItemContainer>
  )
}

export function ListSubHeader({
  children,
  ...rest
}: HeadingProps): React.JSX.Element {
  return (
    <Heading colorScheme="secondary" variant="h4" {...rest}>
      {children}
    </Heading>
  )
}
export const STICKY_HEADER_INDICES = [0]
