import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Auth } from "@aws-amplify/auth"
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  isRejected,
} from "@reduxjs/toolkit"
import * as Sentry from "@sentry/core"

import { COGNITO_ERROR_CODE, CognitoErrorMessage } from "./errors"
import { loadActiveUserAsync, updateUserAccountAsync } from "./farmhq-api"
import { logger, makeFileLogger } from "./logger"
import i18n from "./translations/i18n"
import { isTruthyString, makeValidator, notNullish } from "./type-guards"

import type * as Models from "./models"
import type { RootState, RootThunkConfig } from "./root.reducer"
import type { AnalyticsProps } from "./types"

import type { Reducer } from "@reduxjs/toolkit"
import type { CognitoUser } from "amazon-cognito-identity-js"
import type { LiteralUnion } from "type-fest"

import type { FetchStatus } from "./Requests"

import type { AuthErrorCode } from "./errors"
const fileLogger = makeFileLogger({ fileName: "auth.reducer" })

export const isKnownAuthErrorCode = makeValidator(
  Object.values(COGNITO_ERROR_CODE),
)
export interface AuthError extends Error {
  code: AuthErrorCode
  message: string
  name: AuthErrorCode
}

/**
 * Hide an email address
 * @example
 * // returns 'd**@farmhq.com'
 * maskEmailAddres('dan@farmhq.com')
 *
 */
export function maskEmailAddress(email?: string): string {
  try {
    if (isTruthyString(email)) {
      const [prefix, suffix] = email.split("@")
      if (typeof prefix === "string" && typeof suffix === "string") {
        const starsCount = prefix.length - 1
        const firstChar = prefix.slice(0, 1)
        return [
          firstChar,
          ...Array.from(Array(starsCount)).map(() => "*"),
          "@",
          suffix,
        ].join("")
      }
    }
  } catch (error) {
    logger.error(error)
  }
  return "your email address"
}

const PASSWORD_RULES = {
  lowercaseCount: {
    message: i18n.t("validation:passwordRulesLowercaseCount"),
    value: /^(?=.*[a-z])/,
  },
  minlength: {
    message: i18n.t("validation:passwordRulesMinLength"),
    value: /^(?=.{8})/,
  },
  numericCount: {
    message: i18n.t("validation:passwordRulesNumericCount"),
    value: /^(?=.*\d)/,
  },
  specialCharacterCount: {
    message: i18n.t("validation:passwordRulesSpecialCharacterCount"),
    value: /^(?=.*[!#$%&*@^])/,
  },
  uppercaseCount: {
    message: i18n.t("validation:passwordRulesUppercaseCount"),
    value: /^(?=.*[A-Z])/,
  },
}

export function useValidatePassword() {
  return (value: string) => {
    return [
      PASSWORD_RULES.minlength,
      PASSWORD_RULES.lowercaseCount,
      PASSWORD_RULES.uppercaseCount,
      PASSWORD_RULES.numericCount,
      PASSWORD_RULES.specialCharacterCount,
    ].find((pattern) => {
      if (!pattern.value.test(value)) {
        return true
      }
      return false
    })?.message
  }
}

function handleAuthError(e: unknown): void {
  const error = e as Partial<AuthError> | undefined
  const errorCode = error?.code ?? error?.name
  const errorMessage = error?.message

  if (
    errorCode &&
    errorMessage !== CognitoErrorMessage.NO_CURRENT_USER &&
    errorMessage !== CognitoErrorMessage.REFRESH_TOKEN_EXPIRED &&
    !isKnownAuthErrorCode(errorCode) &&
    // NetworkError is a common error that is not related to cognito or our app
    errorCode !== "NetworkError"
  ) {
    Sentry.captureException(errorCode)
  }
}
export interface AuthFormData {
  codeString: string
  confirmPassword: string
  email: string
  password: string
  errorCode?: LiteralUnion<AuthErrorCode, string>
}

export type SignInParams = Pick<AuthFormData, "email" | "password">

export interface SignInPayload {
  authToken: string | undefined
  email: string | undefined
  sub: string | undefined
}

/**
 * Set the user for sentry and analytics
 */
function handleAuthSuccess({
  analyticsClient,
  values,
}: AnalyticsProps & {
  values: SignInPayload
}) {
  const { email, sub } = values

  Sentry.setUser({
    email,
    id: sub,
    // TODO: Get IP for mobile with expo device
    ip_address: "{{auto}}",
    username: email,
  })

  // TODO: add admin info to user tracking

  analyticsClient
    .identify(values.sub, {
      email: values.email,
      username: values.email,
    })
    .catch((error) => {
      Sentry.captureException(error, {
        tags: {
          "attempted": "identify user",
        },
      })
    })

  return values
}

export const getAuthSessionAsync = createAsyncThunk<
  SignInPayload,
  void,
  RootThunkConfig
>("currentSession", async (_, { extra: { analyticsClient } }) => {
  try {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken()
    const { email, sub } = idToken.decodePayload() as {
      [key: string]: string
    }
    fileLogger.debug(`currentSession ✅ - ${maskEmailAddress(email)}`)
    const authToken = idToken.getJwtToken()

    return handleAuthSuccess({
      analyticsClient,

      values: { authToken, email, sub },
    })
  } catch (error) {
    fileLogger.error("currentSession", error)
    throw error
  }
})

export const signInAsync = createAsyncThunk<
  SignInPayload,
  SignInParams,
  RootThunkConfig
>("signIn", async ({ email, password }, { extra: { analyticsClient } }) => {
  email = email.trim().toLocaleLowerCase()
  password = password.trim()
  try {
    const response = (await Auth.signIn(email, password)) as CognitoUser
    const idToken = response.getSignInUserSession()?.getIdToken()
    const { sub } = idToken?.decodePayload() as {
      [key: string]: string | undefined
    }
    const authToken = idToken?.getJwtToken()
    fileLogger.info(`signIn ✅ - ${maskEmailAddress(email)}`)
    return handleAuthSuccess({
      analyticsClient,
      values: { authToken, email, sub },
    })
  } catch (error) {
    handleAuthError(error)
    fileLogger.error("signIn", error)
    throw error
  }
})

export const signOutAsync = createAsyncThunk("signOut", async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    fileLogger.error("signOut", error)
    throw error
  }
})

export const signUpAsync = createAsyncThunk<
  void,
  SignInParams & { nameFirst?: string; nameLast?: string }
>("signUp", async ({ email, password }) => {
  email = email.trim().toLocaleLowerCase()
  password = password.trim()

  await Auth.signUp(email.trim().toLocaleLowerCase(), password.trim()).catch(
    (error) => handleAuthError(error),
  )
})

export const confirmSignUpAsync = createAsyncThunk<void, AuthFormData>(
  "confirmSignUp",
  async ({ codeString: codeStr, email }) => {
    await Auth.confirmSignUp(
      email.trim().toLocaleLowerCase(),
      codeStr.toLocaleUpperCase(),
    ).catch((error) => {
      handleAuthError(error)
      throw error
    })
  },
)
export const resendSignUpAsync = createAsyncThunk<
  void,
  Pick<AuthFormData, "email">
>("confirmSignUp", async ({ email }) => {
  await Auth.resendSignUp(email.trim().toLocaleLowerCase()).catch((error) => {
    handleAuthError(error)
    throw error
  })
})
export const forgotPasswordAsync = createAsyncThunk<
  void,
  Pick<AuthFormData, "email">
>("forgotPassword", async ({ email }) => {
  if (!email) {
    throw new TypeError("Empty value received for email address")
  }
  await Auth.forgotPassword(email.trim().toLocaleLowerCase()).catch((error) => {
    handleAuthError(error)
    throw error
  })
})

export type ForgotPasswordParams = Pick<
  AuthFormData,
  "codeString" | "email" | "password"
>
export const forgotPasswordSubmitAsync = createAsyncThunk<
  void,
  ForgotPasswordParams & { email: string }
>("forgotPasswordSubmit", async ({ codeString, email, password }) => {
  await Auth.forgotPasswordSubmit(
    email.trim().toLocaleLowerCase(),
    codeString.trim(),
    password.trim(),
  ).catch((error) => {
    handleAuthError(error)
  })
})

export const changePasswordAsync = createAsyncThunk<
  void,
  Pick<AuthFormData, "email" | "password"> & { passwordNew: string }
>("changePassword", async ({ email, password, passwordNew }) => {
  await Auth.changePassword(
    email.trim().toLocaleLowerCase(),
    password.trim(),
    passwordNew.trim(),
  ).catch((error) => {
    handleAuthError(error)
  })
})

interface AuthState {
  authStatus?: FetchStatus
  email?: string
  errorCode?: AuthErrorCode

  userId?: string
  userNameFamily?: Models.UserAccountData["nameFamily"]
  userNameGiven?: Models.UserAccountData["nameGiven"]
}

const initialState: AuthState = {}

const authSlice = createSlice({
  extraReducers: (builder) => {
    return builder
      .addCase(loadActiveUserAsync.fulfilled, (state, { payload }) => {
        state.userNameGiven = payload.userData.nameGiven
        state.userNameFamily = payload.userData.nameFamily
      })
      .addCase(updateUserAccountAsync.fulfilled, (state, { meta }) => {
        state.userNameGiven = meta.arg.nameGiven
        state.userNameFamily = meta.arg.nameFamily
      })
      .addCase(getAuthSessionAsync.pending, (state) => {
        state.authStatus = "pending"
      })
      .addMatcher(
        isAnyOf(getAuthSessionAsync.fulfilled, signInAsync.fulfilled),
        (state, action) => {
          state.authStatus = "fulfilled"
          state.email = action.payload.email
          state.userId = action.payload.sub
        },
      )
      .addMatcher(
        isAnyOf(
          getAuthSessionAsync.rejected,
          signInAsync.rejected,
          signOutAsync.fulfilled,
        ),
        (state) => {
          state.authStatus = "rejected"
          state.email = undefined
          state.userId = undefined
        },
      )
      .addMatcher(
        isAnyOf(
          changePasswordAsync.rejected,
          confirmSignUpAsync.rejected,
          forgotPasswordAsync.rejected,
          forgotPasswordSubmitAsync.rejected,
          resendSignUpAsync.rejected,
          signInAsync.rejected,
          signUpAsync.rejected,
        ),
        (state, { error }) => {
          const errorCode = error.code ?? error.name
          state.errorCode = errorCode as AuthErrorCode
        },
      )
      .addMatcher(isRejected, (state, action) => {
        let message: string | undefined

        // Find error message in response.
        if (action.meta.rejectedWithValue) {
          if (typeof action.payload === "string") {
            message = action.payload
          } else if (
            typeof action.payload === "object" &&
            !Array.isArray(action.payload) &&
            action.payload !== null
          ) {
            const value = action.payload as { [key: string]: unknown }
            if ("message" in value && typeof value.message === "string") {
              message = value.message
            }
          }
        } else {
          message = action.error.message
        }

        // Either of these should log the user out
        if (
          message === CognitoErrorMessage.NO_CURRENT_USER ||
          message === CognitoErrorMessage.REFRESH_TOKEN_EXPIRED
        ) {
          state.authStatus = "rejected"
          state.email = undefined
        }
      })
  },
  initialState,
  name: "auth",
  reducers: {},
})

const authReducer: Reducer<typeof initialState> = authSlice.reducer

export default authReducer
export interface UseAuthFormOptions {
  defaultValues?: Partial<AuthFormData>
}

export function getAuthStatusFromState(state: RootState) {
  return state.auth.authStatus
}

export function getIsUserAuthenticatedFromState(state: RootState) {
  return getAuthStatusFromState(state) === "fulfilled"
}

export function useAuthErrorMessage(
  errorCode: string | undefined,
): string | undefined {
  const { t } = useTranslation("auth")
  if (typeof errorCode === "string") {
    const code = errorCode as AuthErrorCode
    try {
      return t(`${code}`)
    } catch (error) {
      fileLogger.error(error)
      fileLogger.error(`Missed auth error code: ${JSON.stringify(errorCode)}`)
      return "unknown"
    }
  }
  return undefined
}

export function useAuthFormHelpers({ defaultValues }: UseAuthFormOptions) {
  const { t } = useTranslation("auth")
  const form = useForm<AuthFormData>({
    defaultValues: {
      codeString: "",
      confirmPassword: "",
      email: "",
      password: "",
      ...defaultValues,
    },
  })
  const { setValue } = form

  const errorCode = form.watch("errorCode")

  let formErrorMessage: string | undefined

  if (typeof errorCode === "string") {
    const code = errorCode as AuthErrorCode
    let message = t("anUnknownErrorOccurred")

    switch (code) {
      case "CodeDeliveryFailureException": {
        message = t("CodeDeliveryFailureException")
        break
      }
      case "CodeMismatchException": {
        message = t("CodeMismatchException")
        break
      }
      case "ExpiredCodeException": {
        message = t("ExpiredCodeException")
        break
      }
      case "InvalidParemeterException": {
        message = t("InvalidParemeterException")
        break
      }
      case "LimitExceededException": {
        message = t("LimitExceededException")
        break
      }
      case "NotAuthorizedException": {
        message = t("NotAuthorizedException")
        break
      }
      case "UserNotConfirmedException": {
        message = t("UserNotConfirmedException")
        break
      }
      case "UserNotFoundException": {
        message = t("UserNotFoundException")
        break
      }
      case "UsernameExistsException": {
        message = t("UsernameExistsException")
        break
      }
    }
    if (typeof message === "string") {
      formErrorMessage = message
    }
  }

  return {
    errorCode,
    form,
    formErrorMessage,
    handleError: (error: unknown): void => {
      const e = error as Partial<AuthError> | string | null | undefined
      if (notNullish(e)) {
        let code: string | undefined
        if (typeof e === "string") {
          code = e
        } else if (typeof e === "object") {
          if (e.code) {
            code = e.code
          } else if (e.name) {
            code = e.name
          }
        }

        if (typeof code === "string") {
          // Cognito seems to have trailing spaces on at least one error
          code = code.trim()
          setValue("errorCode", code)
        }
      }
    },
  }
}
