import React from "react"
import { StyleSheet } from "react-native"

import { Image, useColorMode } from "./components"
import { Urls } from "./Internal"

import type { ImageProps } from "./components"

type FarmHqLogoFilename =
  | "farmhq-leaf-iso.png"
  | "farmhq-logo-inline.png"
  | "farmhq-logo-stacked.png"

interface Props extends Omit<ImageProps, "source"> {
  name: FarmHqLogoFilename
}
export function useFarmHqLogUri(name: FarmHqLogoFilename) {
  const colorMode = useColorMode()
  return { uri: `${Urls.IMAGE_HOST}/logos/${colorMode}/${name}` }
}
const height = 36
const styles = StyleSheet.create({
  root: {
    height,
    width: height,
  },
})

export function FarmHqLogo({
  alt = "FarmHQ",
  name,
  ...rest
}: Props): React.JSX.Element {
  const logoUrl = useFarmHqLogUri(name)

  return <Image style={styles.root} {...rest} alt={alt} source={logoUrl} />
}
